import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'myst-gm-helper-playerSetup',
    templateUrl: './playerSetup.component.html',
})

export class PlayerSetupComponent {
    @Input() playerSetupPlayerList:any[];
    @Output() finishPlayerSetupEvent = new EventEmitter();

    finishSetup() {
        //Check for Same color
        if(!this.checkUniqueColors()){
            alert("Each player must have a different color");
            return;
        }
        
        //Validate Names
        this.finishPlayerSetupEvent.emit(this.playerSetupPlayerList);
    }

    checkUniqueColors() {
        var colorList = [];
        this.playerSetupPlayerList.forEach(player => {
            colorList.push(player.playerColor);
        });
        return  colorList.length === new Set(colorList).size;
    }
}