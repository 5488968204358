import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'myst-gm-helper-game',
    templateUrl: './game.component.html'
})

export class GameComponent implements OnInit{
    _gamePhase: string;
    _playerList: any[];
    _gameDifficulty: string;
    _numberOfOptions: number;
    _fullCardList: any;

    ngOnInit(){
      this._gamePhase = "gameSettings";
      this._playerList = [];
      this._numberOfOptions = 0;
      this._fullCardList = { "suspects": [],
        "locations":[],
        "weapons":[]
      };
      return;
    }

    finishSettings(val) {
      this._playerList = val.playerList;
      this._gameDifficulty = val.gameDifficulty;
      this._numberOfOptions = val.numberOfOptions;
      this._gamePhase = "playerSettings";
      return;
    }

    finishPlayerSetup(val) {
      this._gamePhase = "enterCards";
      return;
    }

    finishEnterCards(val) {
      this._gamePhase = "mainGame";
      return;
    }
}