<!-- Game Settings -->
<myst-gm-helper-gameSettings 
    *ngIf="_gamePhase =='gameSettings'" 
    (finishGameSettingsEvent)='finishSettings($event)'></myst-gm-helper-gameSettings>

<!-- Player Info -->
<myst-gm-helper-playerSetup
    *ngIf="_gamePhase == 'playerSettings'"
    [playerSetupPlayerList]="_playerList"
    (finishPlayerSetupEvent)='finishPlayerSetup($event)'></myst-gm-helper-playerSetup>

<!-- Enter Card Numbers -->
<myst-gm-helper-enterCards
    *ngIf="_gamePhase == 'enterCards'"
    [enterCardsPlayerList]="_playerList"
    [enterCardsNumberOfOptions]="_numberOfOptions"
    [enterCardsFullCardList]="_fullCardList"
    (finishEnterCardsEvent)='finishEnterCards($event)'></myst-gm-helper-enterCards>


<!-- Start Game -->
    <!-- Track Turns -->
    <!-- Track solved -->
    <!-- Track players given visions -->
    <!-- Track Crows -->
<div *ngIf="_gamePhase == 'mainGame'">
    <div class="container">
        <div class="row">
            <div class="col-sm seer{{player.playerColor}}" *ngFor="let player of _playerList; index as i">
                {{player.playerName == null ? 'Player ' + (i + 1) : player.playerName}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm" *ngFor="let player of _playerList; index as i">
                <img src="assets/cardImages/suspects/{{player.playerSuspect}}.png" title="{{i + 1}}_suspect" class="img-fluid"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm" *ngFor="let player of _playerList; index as i">
                <img src="assets/cardImages/locations/{{player.playerLocation}}.png" title="{{i + 1}}_location" class="img-fluid"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm" *ngFor="let player of _playerList; index as i">
                <img src="assets/cardImages/weapons/{{player.playerWeapon}}.png" title="{{i + 1}}_weapon" class="img-fluid"/>
            </div>
        </div>
    </div>
</div>