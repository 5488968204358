<!-- Number of Players -->
<h2>Number of Seers</h2>
    <div class="btn-group" role="group"  aria-label="Number of Seers">
        <button type="button" 
            *ngFor="let seerOption of seerOptions" 
            class="btn btn-secondary" [class.active]="seerOption == _numSeers" 
            (click)="_numSeers = seerOption">{{seerOption}}</button>
    </div>

<!-- Game Difficutly -->
<h2>Game Difficulty</h2>
<div class="btn-toolbar" role="toolbar" aria-lable="Difficulty toolbar">
    <div class="btn-group" role="group">
        <button type="button" 
            *ngFor="let difficultyOption of difficulyOptions"
            class="btn btn-secondary" [class.active]="difficultyOption == _difficulty"  
            (click)="_difficulty = difficultyOption">{{difficultyOption}}</button>
    </div>
</div>

<button type="button" class="btn btn-primary" aria-label="Next Step" (click)="finishSettings()">Next</button>