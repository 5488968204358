<h3>Enter Player Info</h3>
<div class="container">
  <div class="row">
    <div class="col-md" *ngFor="let player of playerSetupPlayerList; index as i" >
      <!-- Play Name -->
      <div class="form-group">
        <label for="playerName{{i}}">Name</label>
        <input type="string" class="form-control" id="playerName{{i}}" aria-describedby="player name" placeholder="Player Name" 
          [(ngModel)]='playerSetupPlayerList[i].playerName'>
      </div>
      <!-- Player Color -->
      <div class="col-sm">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-secondary seer{{player.playerColor}}" id="dropDownColor{{i}}" ngbDropdownToggle>Player {{i+1}} Color: {{player.playerColor}}</button>
          <div ngbDropdownMenu aria-labelledby="Player Color">
            <button ngbDropdownItem (click)="playerSetupPlayerList[i].playerColor = 'Purple'">Purple</button>
            <button ngbDropdownItem (click)="playerSetupPlayerList[i].playerColor = 'Blue'">Blue</button>
            <button ngbDropdownItem (click)="playerSetupPlayerList[i].playerColor = 'White'">White</button>
            <button ngbDropdownItem (click)="playerSetupPlayerList[i].playerColor = 'Yellow'">Yellow</button>
            <button ngbDropdownItem (click)="playerSetupPlayerList[i].playerColor = 'Red'">Red</button>
            <button ngbDropdownItem (click)="playerSetupPlayerList[i].playerColor = 'Black'">Black</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-primary" aria-label="Next Step" (click)="finishSetup()">Next</button>
</div>