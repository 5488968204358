import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'myst-gm-helper-enterCards',
    templateUrl: './enterCards.component.html',
})

export class EnterCardsComponent implements OnInit {
    @Input() enterCardsNumberOfOptions:number;
    @Input() enterCardsFullCardList:any;
    @Input() enterCardsPlayerList:any;
    @Output() finishEnterCardsEvent = new EventEmitter();
    public checkboxGroupFormSuspects: FormGroup;
    public checkboxGroupFormLocations: FormGroup;
    public checkboxGroupFormWeapons: FormGroup;
    
    suspectOptions = [];
    locationOptions = [];
    weaponOptions = [];
    constructor(private formBuilder: FormBuilder) {}

    validateForm(list, groupName, min,max) {
        var count = 0;

        for(var i = min; i <= max;i++) {
           if(list.value[i]===true){
               count++;
           }
        }

        if(count > this.enterCardsNumberOfOptions) {
            alert("Too many " + groupName + " selected.");
            return false;
           }
          
        else if(count < this.enterCardsNumberOfOptions) {
            alert("Not enough " + groupName + " selected");
            return false;
        } 

        return true;
    };
    loadCardLists(list,min,max) {
        var cardList = [];
        var count = 0;
        for(var i = min; i <= max;i++) {
           if(list.value[i]===true){
               count++;
               cardList.push(i);
           }
        }
        return cardList;
    }
    ngOnInit() {
        this.checkboxGroupFormSuspects = this.formBuilder.group(this.setupDropDownList(1,18));
        this.checkboxGroupFormLocations = this.formBuilder.group(this.setupDropDownList(19,36));
        this.checkboxGroupFormWeapons = this.formBuilder.group(this.setupDropDownList(37,54));

        this.suspectOptions = this.setupOptionList(1,18);
        this.locationOptions = this.setupOptionList(19,36);
        this.weaponOptions = this.setupOptionList(37,54);

        
    }

    setupDropDownList(min,max) {
        var options = {}
        for(var i = min; i <= max; i++) {
            options[i] =false;
        }
        return  options;
    }

    setupOptionList(min,max) {
        var options = [];
        for(var i = min; i <= max; i++) {
            options.push(i);
        }
        return options;
    }

    finishEnterCards() {
        //Validate and load suspect list
        if(!this.validateForm(this.checkboxGroupFormSuspects, "Suspects",1,18)) return;
        this.enterCardsFullCardList.suspects = this.loadCardLists(this.checkboxGroupFormSuspects,1,18);

        //Validate and load location list
        if(!this.validateForm(this.checkboxGroupFormLocations, "Locations",19,36)) return;
        this.enterCardsFullCardList.locations = this.loadCardLists(this.checkboxGroupFormLocations,19,36);

        //Validate and load weapon list
        if(!this.validateForm(this.checkboxGroupFormWeapons, "Weapons",37,54)) return;
        this.enterCardsFullCardList.weapons = this.loadCardLists(this.checkboxGroupFormWeapons,37,54);

        //Assign Cards
        this.assignPlayerCards();
        //Alert Phase complete
        this.finishEnterCardsEvent.emit();
    }

    getRandomInt(min, max,listLength) {
        const cardSet = new Set();
        while(cardSet.size !== listLength ) {
            min = Math.ceil(min);
            max = Math.floor(max);
            cardSet.add(Math.floor(Math.random() * (max - min + 1)) + min); //The maximum is inclusive and the minimum is inclusive 
        }
        return cardSet;
      }

    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
        
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
        
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        
        return array;
    }
    
    assignPlayerCards () {
        var randoSuspects = Array.from(this.enterCardsFullCardList.suspects);
        // //Use to shuffle List
        this.shuffle(randoSuspects);
        //loop through indexes based on number of players and assign card
        for(var i= 0; i < this.enterCardsPlayerList.length; i++){
            this.enterCardsPlayerList[i].playerSuspect = randoSuspects[i];
        }
   
        var randoLocations = Array.from(this.enterCardsFullCardList.locations);
        // //Use to shuffle List
        this.shuffle(randoLocations);
        //loop through indexes based on number of players and assign card
        for(var i= 0; i < this.enterCardsPlayerList.length; i++){
            this.enterCardsPlayerList[i].playerLocation = randoLocations[i];
        }

        var randoWeapons = Array.from(this.enterCardsFullCardList.weapons);
        // //Use to shuffle List
        this.shuffle(randoWeapons);
        //loop through indexes based on number of players and assign card
        for(var i= 0; i < this.enterCardsPlayerList.length; i++){
            this.enterCardsPlayerList[i].playerWeapon = randoWeapons[i];
        }
        return;
        
    }
}