import { Component, EventEmitter, Output } from '@angular/core';
import { iif } from 'rxjs';

@Component({
    selector: 'myst-gm-helper-gameSettings',
    templateUrl: './gameSettings.component.html',
})

export class GameSettingsComponent {
    _numSeers:number = 3;
    _difficulty:string = "Medium";

    //Options
    seerOptions = [1, 2, 3, 4, 5, 6];
    difficulyOptions = ["Easy", "Medium", "Hard"];

    @Output() finishGameSettingsEvent = new EventEmitter();

    finishSettings() {
        var playerList = new Array();

        var numberOfOptions;
        //Calculate Number of Options for each section
        numberOfOptions = this.calculateCards();

        //Prep Player List
        let i: number = this._numSeers;
        while(i > 0) {
            playerList.push({ 
                "playerName": null,
                "playerColor": null,
                "playerSuspect":null,
                "playerLocation":null,
                "playerWeapon":null,
                "playerTurnComplete": false,
                "playerPhase1Complete":false, });
            i--;
        }

        //Move Game to Next Phase
        var nextPhase = {
            "playerList":playerList,
            "gameDifficulty":this._difficulty,
            "numberOfOptions":numberOfOptions
        }
        return this.finishPhase(nextPhase);
    }
    
    private finishPhase(nextPhase: { playerList: any[], gameDifficulty: string, numberOfOptions: any }) {
        this.finishGameSettingsEvent.emit(nextPhase);
        return;
    }

    /**
     * Returns number of card options based on number of Seers and the Difficutly
     */
    calculateCards() {
        if(this._difficulty == "Easy") {
            if(this._numSeers == 1) return 4;
            else if(this._numSeers == 2 || this._numSeers == 3) return 5;
            else if(this._numSeers == 4 || this._numSeers == 5) return 6;
            else return 7;
        }
        else if(this._difficulty == "Medium") {
            if(this._numSeers == 1) return 5;
            else if(this._numSeers == 2 || this._numSeers == 3) return 6;
            else if(this._numSeers == 4) return 7;
            else return 8;
        }
        else {
            if(this._numSeers == 1) return 6;
            else if(this._numSeers == 2 || this._numSeers == 3) return 7;
            else if(this._numSeers == 4) return 8;
            else return 9;
        }
    }
}
