<h2>Select Card Numbers</h2>
<h4><i> Select {{enterCardsNumberOfOptions}}</i></h4>
<!-- add for loop for number of cards-->
<div class="container">

    <!-- <h3>Suspects</h3>
    <div class="row">
      <div class="col-md" *ngFor="let suspect of enterCardsFullCardList.suspects; index as i" >

        <div class="col-sm">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-secondary" id="dropDownSuspect{{i}}" ngbDropdownToggle>{{enterCardsFullCardList.suspects[i]}}</button>
            <div ngbDropdownMenu aria-labelledby="Suspect Card Number" >
              <button *ngFor="let option of suspectOptions" ngbDropdownItem (click)="enterCardsFullCardList.suspects[i] = option" >{{option}}</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <form [formGroup]="checkboxGroupFormSuspects">
      <h3>Suspects</h3>
      <div class="btn-group btn-group-toggle" *ngFor="let option of suspectOptions">
        <label class="btn-{{checkboxGroupFormSuspects.value[option]==true?'primary' : 'secondary'}}" ngbButtonLabel>
          <input type="checkbox" formControlName="{{option}}" ngbButton> {{option}}
        </label>
      </div>
    </form>
    <form [formGroup]="checkboxGroupFormLocations">
      <h3>Locations</h3>
      <div class="btn-group btn-group-toggle" *ngFor="let option of locationOptions">
        <label class="btn-{{checkboxGroupFormLocations.value[option]==true?'primary' : 'secondary'}}" ngbButtonLabel>
          <input type="checkbox" formControlName="{{option}}" ngbButton> {{option}}
        </label>
      </div>
    </form>
    <form [formGroup]="checkboxGroupFormWeapons">
      <h3>Weapons</h3>
      <div class="btn-group btn-group-toggle" *ngFor="let option of weaponOptions">
        <label class="btn-{{checkboxGroupFormWeapons.value[option]==true?'primary' : 'secondary'}}" ngbButtonLabel>
          <input type="checkbox" formControlName="{{option}}" ngbButton> {{option}}
        </label>
      </div>
    </form>
    <hr>
    <button type="button" class="btn btn-primary" aria-label="Next Step" (click)="finishEnterCards()">Next</button>
</div>